// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
var flyoutNav = true;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

if ($('body').hasClass('fundraiserType')) {
    $('.appealActionsWrapper').insertBefore('.fundraiserFooterWrapper');
}